import {
  Chip,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import React, { useState } from 'react';
import { commonStyles } from '../../../../../../styles/common';
import ConfirmationPopup from './ConfirmationPopup';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DateTime } from 'luxon';
import { capitalizeFirstLetter } from '../../../../../home/allAssets/util';

interface Project {
  instanceid: string;
  statusutc: string;
  statusmsg: string;
}

interface ProjectsTableProps {
  projects: Project[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...commonStyles(theme),
    table: {
      minWidth: 650,
    },
    tableHead: {
      backgroundColor: theme.palette.table.headerBackground + ' !important',
    },
    tableHeadCell: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    chip: {
      backgroundColor: theme.palette.colors.lightGreen,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    checkIcon: {
      color: theme.palette.successText,
    },
    blockIcon: {
      color: theme.palette.errorText,
    },
    infoIcon: {
      color: theme.palette.warningText,
    },
  }),
);

const ProjectsTable = ({ isToolAdmin, projects, details, onChange }: any) => {
  const classes = useStyles();
  const projectDetails = details?.data?.[0]?.data?.[0]?.data;

  enum status {
    ENABLED = 'enabled',
    WORKING = 'working',
    DISABLED = 'disabled',
  }

  const emptyPopupState = {
    open: false,
    popupContent: {
      title: '',
      description: '',
      subDescription: '',
      enabled: false,
    },
    onConfirm: () => {},
    onClose: () => {},
  };

  const [confirmationPopup, setConfirmationPopup] = useState({
    ...emptyPopupState,
  });

  const handleCloseConfirmationPopup = () => {
    setConfirmationPopup({ ...emptyPopupState });
  };

  const handleConfirmEnablePopup = (project: Project) => {
    onChange(project);
    handleCloseConfirmationPopup();
  };

  const handleConfirmationPopup = (project: Project) => {
    const enabled = project?.statusmsg
      ? project?.statusmsg?.toLowerCase() === status.ENABLED
      : false;
    setConfirmationPopup({
      open: true,
      popupContent: {
        title: enabled ? 'Disable - Planner QE Bot' : 'Enable - Planner QE Bot',
        description: enabled
          ? 'Are you sure you want to disable this service?'
          : 'Are you sure you want to enable this service?',
        subDescription: enabled
          ? 'This feature will be disabled from the project.'
          : 'This feature will be enabled from the project.',

        enabled: enabled,
      },
      onConfirm: () => {
        handleConfirmEnablePopup(project);
      },
      onClose: () => {
        handleCloseConfirmationPopup();
      },
    });
  };

  return (
    <>
      {details && details?.data?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table
            size="small"
            className={classes.table}
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow classes={{ root: classes.tableHead }}>
                <TableCell>
                  <Typography
                    classes={{ root: classes.tableHeadCell }}
                    variant="subtitle2"
                  >
                    Project Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    classes={{ root: classes.tableHeadCell }}
                    variant="subtitle2"
                  >
                    Project ID
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    classes={{ root: classes.tableHeadCell }}
                    variant="subtitle2"
                  >
                    Last Updated
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    classes={{ root: classes.tableHeadCell }}
                    variant="subtitle2"
                  >
                    Status
                  </Typography>
                </TableCell>
                {isToolAdmin && (
                  <TableCell>
                    <Typography
                      classes={{ root: classes.tableHeadCell }}
                      variant="subtitle2"
                    >
                      Enable/Disable
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {projectDetails?.map((project: any) => {
                let instanceId = project?.projectid;
                let projectOrg = project?.projectorg;
                console.log('projectOrg', projectOrg);
                if (instanceId.includes(`${projectOrg}-`)) {
                  instanceId = instanceId.split(`${projectOrg}-`)[1];
                }
                const projectDetail =
                  projects?.length !== 0 &&
                  projects?.find((detail: Project) => {
                    let projectId = detail?.instanceid;
                    if (projectId.includes(`${projectOrg}-`)) {
                      projectId = projectId.split(`${projectOrg}-`)[1];
                    }
                    return projectId === instanceId;
                  });
                return (
                  <TableRow key={project?.projectid}>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {/* {projectDetail?.projectname || instanceid} */}
                        {project?.projectname}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {/* {projectDetail?.projectid || instanceid} */}
                        {project?.projectid}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {projectDetail?.statusutc
                          ? DateTime.fromISO(projectDetail?.statusutc).toFormat(
                              'dd MMM yyyy, hh:mm a',
                            )
                          : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {/* {JSON.stringify(project.enabled)} */}
                        <Chip
                          classes={{ root: classes.chip }}
                          label={
                            projectDetail?.statusmsg
                              ? capitalizeFirstLetter(projectDetail?.statusmsg)
                              : capitalizeFirstLetter(status.DISABLED)
                          }
                          icon={
                            projectDetail?.statusmsg ? (
                              projectDetail?.statusmsg?.toLowerCase() ===
                              status.ENABLED ? (
                                <CheckCircleIcon
                                  classes={{ root: classes.checkIcon }}
                                />
                              ) : projectDetail?.statusmsg?.toLowerCase() ===
                                status.WORKING ? (
                                <InfoOutlinedIcon
                                  classes={{ root: classes.infoIcon }}
                                />
                              ) : (
                                <BlockIcon
                                  classes={{ root: classes.blockIcon }}
                                />
                              )
                            ) : (
                              <BlockIcon
                                classes={{ root: classes.blockIcon }}
                              />
                            )
                          }
                          // color={project.enabled ? 'primary' : 'secondary'}
                          size="small"
                        />
                      </Typography>
                    </TableCell>
                    {isToolAdmin && (
                      <TableCell>
                        <Switch
                          checked={
                            projectDetail?.statusmsg
                              ? projectDetail?.statusmsg?.toLowerCase() ===
                                status.ENABLED
                              : false
                          }
                          onClick={() =>
                            handleConfirmationPopup(
                              projectDetail === undefined
                                ? project
                                : projectDetail,
                            )
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No projects found.</Typography>
      )}
      {confirmationPopup?.open && <ConfirmationPopup {...confirmationPopup} />}
    </>
  );
};

export default ProjectsTable;
