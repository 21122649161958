import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import BackButton from '../../../../../utils/backButton';
import { HelpComponent } from '../../../../../utils/helpComponent';
import { commonStyles } from '../../../../../../styles/common';
import HELP_URL from '../../../../../utils/helpLinkConstant';
import ProjectsTable from '../components/ProjectsTable';
import { useParams } from 'react-router';
import { Content } from '@backstage/core-components';
import { useAddProject } from '../hooks/useAddProject';
import { useProvisionedServiceDetails } from '../hooks/useProvisionedServiceDetails';
import { useGetProvisionedTools } from '../hooks/useGetProvisionedTools';

const TIMEOUT_SECONDS = 1000;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...commonStyles(theme),
    '@global': {
      'header + div': {
        display: 'none',
      },
      'header ~ article': {
        padding: '0px !important',
      },
    },
    listPageHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing(1.25)}px`,
    },
  }),
);

interface ProjectListPageProps {
  isToolAdmin: boolean;
  service: string;
}

const ProjectListPage = ({ isToolAdmin, service }: ProjectListPageProps) => {
  const classes = useStyles();
  const { projectId: apmNumber } = useParams();
  const { addProject } = useAddProject();

  const [provisionedDetails, loading, refreshProvisioned] =
    useProvisionedServiceDetails(apmNumber, service);

  const [provisionedToolsAPM, isLoading, refreshProvisionedTools] =
    useGetProvisionedTools(apmNumber, service);

  const handleChange = (project: any) => {
    addProject(
      project?.instanceid
        ? project?.instanceid
        : service === 'ado'
        ? `${project?.projectorg}-${project?.projectid}`
        : project?.projectid,
      project?.statusmsg ? project?.statusmsg !== 'Enabled' : true,
      service,
    ).then(() => {
      setTimeout(() => {
        refreshProvisionedTools();
      }, TIMEOUT_SECONDS);
    });
  };

  return (
    <Content>
      <Grid container spacing={2} className={classes.padding2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.flexSpacebetween}
        >
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.PERFORMANCE_TEST_RUNS_PAGE} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.listPageHeader}
        >
          <div
            dangerouslySetInnerHTML={{
              __html:
                (provisionedToolsAPM?.data?.provisioned?.length &&
                  provisionedToolsAPM?.data?.provisioned[0]?.metadata?.image) ||
                '',
            }}
          />
          <Typography variant="h5">Planner QE Bot</Typography>
        </Grid>
        {(loading || isLoading) && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LinearProgress />
          </Grid>
        )}
        {!loading &&
          !isLoading &&
          (provisionedDetails?.data?.length > 0 ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ProjectsTable
                isToolAdmin={isToolAdmin}
                projects={provisionedToolsAPM?.data?.provisioned}
                details={provisionedDetails}
                onChange={handleChange}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.marginLeftSmall}
            >
              <Typography>No mapped projects available.</Typography>
            </Grid>
          ))}
      </Grid>
    </Content>
  );
};

export default ProjectListPage;
