import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Progress } from '@backstage/core-components';
import { AllAssetsTable } from './allAssetsTable/allAssetsTable';
// import AIMLIcon from '@material-ui/icons/PsychologyRounded';
import {
  ListItem,
  Button as MaterialButton,
  Grid,
  LinearProgress,
  makeStyles,
  createStyles,
  Button,
  useTheme,
  useMediaQuery,
  Theme
} from '@material-ui/core';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  UserListFilter,
  useEntityOwnership,
  useStarredEntities,
  CatalogFilterLayout,
} from '@backstage/plugin-catalog-react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import CodeIcon from '../../Root/icons/codeIcon';
import ExtensionIcon from '@material-ui/icons/Extension';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InfoIcon from '@material-ui/icons/Info';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import RateReviewIcon from '@material-ui/icons/RateReview';
import UpdateIcon from '@material-ui/icons/Update';
import * as constant from '../../utils/constant';
import AIIcon from '../../Root/icons/aiIcon';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import AllAssetsCardGrid from './cardView/AllAssetsCardGrid';
import { AuthContext } from '../../../providers/AuthProvider';
import { subscriberApiRef } from '../../../apis/subscriberApi';
import SalesforceIcon from '../../Root/icons/salesforceIcon';
import {
  notificationApiRef,
  NotificationApi,
} from '../../../apis/notificationApi';
import DesignIcon from '../../catalog/icons/DesignIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
    toggleBtnGroup: {
      padding: '5.5px 0px',
    },
    toggleBtns: {
      padding: '0px 5px',
    },
    assetGuide: {
      textTransform: 'capitalize',
      float: 'right',
    },
    alert: {
      width: '100%',
      margin: `${theme.spacing(1)}px`,
      marginLeft: '0px',
      '& svg': {
        marginRight: '5px'
      }
    }
  }),
);
const initialState = {
  isCodeFilterClick: false,
  isApiFilterClick: false,
  isAllFilterClick: true,
  isFavoriteFilterClick: false,
  isIacFilterClick: false,
  isSubscribeFilterClick: false,
  isSalesforceFilterClick: false,
  isTopRatingFilterClick: false,
  isRecentlyPublishedFilterClick: false,
  isAIFilterClick: false,
  isExtensionFilterClick: false,
  isUIUXFilterClick: false
};

const useCatalogEntity = () => {
  const catalogApi = useApi(catalogApiRef);
  const {
    value: entity,
    error,
    loading,
  } = useAsync(() => catalogApi.getEntities());

  return { entity, error, loading };
};

export const AllAssets = (props: any) => {
  const entityData = useCatalogEntity();
  const classes = useStyles();
  const { entity, error, loading } = entityData;
  const [allAssetsData, setAllAssetsData] = useState<any>([]);
  const [isClickSubscribeIcon, setClickSubscribeIcon] = useState<any>(false);
  const [isError, setError] = useState<any>(false);
  const [subscribeData, setSubscribeData] = useState<any>([]);
  const [checkClickItem, setCheckClickItem] = useState<any>(initialState);
  const [view, setView] = useState<any>('');
  const { isStarredEntity } = useStarredEntities();
  const { profEmail, isAdmin } = useContext(AuthContext);
  const subscriberApi = useApi(subscriberApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const theme: Theme = useTheme();
  const isFilterCollapsed = useMediaQuery(theme.breakpoints.up('md'));

  const userEmail = profEmail;

  const { isOwnedEntity } = useEntityOwnership();
  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const allCount =
    entity &&
    entity?.items.filter(
      (item: any) =>
        (item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase() &&
          constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
          item?.metadata?.devxstate?.toLowerCase() !==
            constant.NOT_APPROVED.toLowerCase()) ||
        (item.kind?.toLowerCase() === constant.API.toLowerCase() &&
          item?.metadata?.devxstate?.toLowerCase() !==
            constant.NOT_APPROVED.toLowerCase()),
    ).length;

  const codeCount =
    entity &&
    entity?.items.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase() &&
        constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
        item?.spec?.type?.toLowerCase() !==
          constant.IAC_COMPONENT_TYPE.toLowerCase() &&
        item?.spec?.type?.toLowerCase() !==
          constant.SALESFORCE_COMPONENT_TYPE.toLowerCase(),
    ).length;

  const apiCount =
    entity &&
    entity?.items.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.API,
    ).length;

  const iacCount =
    allAssetsData &&
    allAssetsData.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.IAC_COMPONENT_TYPE.toLowerCase(),
    ).length;

  const salesforceCount =
    allAssetsData &&
    allAssetsData.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.SALESFORCE_COMPONENT_TYPE.toLowerCase(),
    ).length;

  const aiCount =
    allAssetsData &&
    allAssetsData.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.AI_COMPONENT_TYPE.toLowerCase(),
    ).length;

  const extensionCount = 
    allAssetsData &&
    allAssetsData.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.EXTENSION_COMPONENT_TYPE.toLowerCase(),
    ).length;

    const uiuxCount = 
    allAssetsData &&
    allAssetsData.filter(
      (item: any) =>{
        console.log(item?.metadata?.devxstate?.toLowerCase(), item.kind, item.spec.type);
        return  item?.metadata?.devxstate?.toLowerCase() !==
        constant.NOT_APPROVED.toLowerCase() &&
      item.kind?.toLowerCase() === constant.COMPONENT &&
      item?.spec?.type?.toLowerCase() ===
        constant.UIUX_COMPONENT_TYPE.toLowerCase()
      }
    ).length;

  useEffect(() => {
    const storedView = sessionStorage.getItem('assets-view');
    if (view) {
      sessionStorage.setItem('assets-view', view);
    } else {
      const newView =
        storedView === 'table' || storedView === 'card' ? storedView : 'card';
      setView(newView);
    }
  }, [view]);

  const getSubscriptionCount = () => {
    const subscriptionData: any[] = [];
    allAssetsData &&
      allAssetsData.map((item: any) => {
        subscribeData &&
          subscribeData.map((subscribeItem: any) => {
            if (
              item.metadata?.name.toLowerCase() ===
                subscribeItem?.assetName.toLowerCase() &&
              subscribeItem?.email?.toLowerCase() === userEmail?.toLowerCase()
            ) {
              subscriptionData.push(item);
            }
          });
      });

    return subscriptionData;
  };

  const subscriptionCount = getSubscriptionCount().length;

  useEffect(() => {
    const entityItems =
      entity &&
      entity?.items.filter(
        (item: any) =>
          (item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase() &&
            constant.COMPONENT_TYPE.includes(item.spec?.type?.toLowerCase()) &&
            item?.metadata?.devxstate?.toLowerCase() !==
              constant.NOT_APPROVED.toLowerCase()) ||
          (item.kind?.toLowerCase() === constant.API.toLowerCase() &&
            item?.metadata?.devxstate?.toLowerCase() !==
              constant.NOT_APPROVED.toLowerCase()),
      );
    setAllAssetsData(entityItems);

    localStorage.setItem('isReview', 'false');
  }, [entity, error, loading]);

  useEffect(() => {}, [allAssetsData, subscribeData]);

  useEffect(() => {
    subscriberApi
      .getSubscribersCount()
      .then((data: any) => {
        setSubscribeData(data || []);
      })
      .catch((error: any) => {
        setError(true);
        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  }, [isClickSubscribeIcon]);

  const starredFilterItem =
    allAssetsData &&
    allAssetsData.length &&
    allAssetsData.filter((item: any) => starredFilter.filterEntity(item));

  const handleOnClickForCode = () => {
    setCheckClickItem({
      ...initialState,
      isCodeFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForApi = () => {
    setCheckClickItem({
      ...initialState,
      isApiFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForFavorite = () => {
    setCheckClickItem({
      ...initialState,
      isFavoriteFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForTopRating = () => {
    setCheckClickItem({
      ...checkClickItem,
      isTopRatingFilterClick: !checkClickItem.isTopRatingFilterClick,
      isRecentlyPublishedFilterClick: false,
    });
  };
  const handleOnClickRecentlyPublished = () => {
    setCheckClickItem({
      ...checkClickItem,
      isRecentlyPublishedFilterClick:
        !checkClickItem.isRecentlyPublishedFilterClick,
      isTopRatingFilterClick: false,
    });
  };

  const handleOnClickForSubscribe = () => {
    setCheckClickItem({
      ...initialState,
      isSubscribeFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForAll = () => {
    setCheckClickItem(initialState);
  };

  const handleOnClickForIac = () => {
    setCheckClickItem({
      ...initialState,
      isIacFilterClick: true,
      isAllFilterClick: false,
    });
  };
  const handleOnClickForSalesforce = () => {
    setCheckClickItem({
      ...initialState,
      isSalesforceFilterClick: true,
      isAllFilterClick: false,
    });
  };
  const handleOnClickForAI = () => {
    setCheckClickItem({
      ...initialState,
      isAIFilterClick: true,
      isAllFilterClick: false,
    });
  };
  const handleOnClickForExtension = () => {
    setCheckClickItem({
      ...initialState,
      isExtensionFilterClick: true,
      isAllFilterClick: false,
    });
  }
  const handleOnClickForUIUX = () => {
    setCheckClickItem({
      ...initialState,
      isUIUXFilterClick: true,
      isAllFilterClick: false,
    });
  }

  if (loading) {
    return (
      <div>
        <Progress />
      </div>
    );
  }

  return (
    <div className="codePage noPadding">
      {loading && <LinearProgress />}
      <Grid item md={12} xs={12}>
        <BackButton />
        <div className="ToggleButtonGroup">
          <ToggleButtonGroup
            value={view}
            exclusive={false}
            onChange={v => {
              setView(view === 'table' ? 'card' : 'table');
            }}
            className={classes.toggleBtnGroup}
          >
            <ToggleButton
              value="table"
              className={classes.toggleBtns}
              color="primary"
            >
              Table
            </ToggleButton>
            <ToggleButton
              value="card"
              className={classes.toggleBtns}
              color="primary"
            >
              Card
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <HelpComponent helpUrl={HELP_URL.AllAssets} />
        <Button
          className={classes.assetGuide}
          variant="contained"
          color="primary"
          href="/help/assetpublish/"
        >
          Asset Publishing Guide
        </Button>
      </Grid>
      {/* <Alert 
        severity='info' 
        className={classes.alert}
      >
        We are currently facing an issue with the WSo2 sync and the resuable API list is not populating fully. Sorry for the inconvenience.
      </Alert> */}
      <CatalogFilterLayout>
        <CatalogFilterLayout.Filters>
          <div className={`customFilters ${classes.themeColor}`}>
            <ListItem
              className={checkClickItem.isAllFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="All"
                onClick={() => handleOnClickForAll()}
                startIcon={<DoneAllIcon />}
              >
                All
                <span className="CountSpan">{allCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isFavoriteFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="Favorite"
                onClick={() => handleOnClickForFavorite()}
                startIcon={<BookmarkIcon />}
              >
                Bookmark
                <span className="CountSpan">
                  {starredFilterItem && starredFilterItem.length}
                </span>
              </MaterialButton>
            </ListItem>

            <ListItem
              className={checkClickItem.isSubscribeFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="Subscribe"
                onClick={() => handleOnClickForSubscribe()}
                startIcon={<SubscriptionsIcon />}
              >
                My Subscription
                <span className="CountSpan">{subscriptionCount}</span>
              </MaterialButton>
            </ListItem>

            {view !== 'table' && (
              <>
                <ListItem
                  className={
                    checkClickItem.isTopRatingFilterClick ? 'active' : ''
                  }
                >
                  <MaterialButton
                    variant="outlined"
                    name="Rating"
                    onClick={() => handleOnClickForTopRating()}
                    startIcon={<RateReviewIcon />}
                  >
                    Highly Rated
                  </MaterialButton>
                </ListItem>

                <ListItem
                  className={
                    checkClickItem.isRecentlyPublishedFilterClick
                      ? 'active'
                      : ''
                  }
                >
                  <MaterialButton
                    variant="outlined"
                    name="Recent"
                    onClick={() => handleOnClickRecentlyPublished()}
                    startIcon={<UpdateIcon />}
                  >
                    Recently Published
                  </MaterialButton>
                </ListItem>
              </>
            )}
            <ListItem
              className={checkClickItem.isCodeFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="code"
                onClick={() => handleOnClickForCode()}
                startIcon={<CodeIcon />}
              >
                Code / Library<span className="CountSpan">{codeCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isApiFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="api"
                onClick={() => handleOnClickForApi()}
                startIcon={<ExtensionIcon />}
              >
                API
                <span className="CountSpan">{apiCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isIacFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="iac"
                onClick={() => handleOnClickForIac()}
                startIcon={<InfoIcon />}
              >
                IaC
                <span className="CountSpan">{iacCount}</span>
              </MaterialButton>
            </ListItem>
            {salesforceCount > 0 && (
              <ListItem
                className={
                  checkClickItem.isSalesforceFilterClick ? 'active' : ''
                }
              >
                <MaterialButton
                  variant="outlined"
                  name="salesforce"
                  onClick={() => handleOnClickForSalesforce()}
                  startIcon={<SalesforceIcon />}
                >
                  Salesforce
                  <span className="CountSpan">{salesforceCount}</span>
                </MaterialButton>
              </ListItem>
            )}
            {aiCount > 0 && (
              <ListItem
                className={checkClickItem.isAIFilterClick ? 'active' : ''}
              >
                <MaterialButton
                  variant="outlined"
                  name="aiml"
                  onClick={() => handleOnClickForAI()}
                  startIcon={<AIIcon fontSize="large" />}
                >
                  AI/ML
                  <span className="CountSpan">{aiCount}</span>
                </MaterialButton>
              </ListItem>
            )}
            {
              extensionCount > 0 && (
                <ListItem
                  className={checkClickItem.isExtensionFilterClick ? 'active' : ''}
                >
                  <MaterialButton
                    variant="outlined"
                    name="extension"
                    onClick={() => handleOnClickForExtension()}
                    startIcon={<WidgetsOutlinedIcon fontSize="large" />}
                  >
                    Extensions
                    <span className="CountSpan">{extensionCount}</span>
                  </MaterialButton>
                </ListItem>
              )
            }
            {
              uiuxCount > 0 && (
                <ListItem
                className={checkClickItem.isUIUXFilterClick ? 'active' : ''}
              >
                <MaterialButton
                  variant="outlined"
                  name="uiux"
                  onClick={() => handleOnClickForUIUX()}
                  startIcon={<DesignIcon fontSize="large" filled={true}/>}
                >
                  UI/UX
                  <span className="CountSpan">{uiuxCount}</span>
                </MaterialButton>
              </ListItem>
              )
            }
          </div>
        </CatalogFilterLayout.Filters>
        <CatalogFilterLayout.Content className="TabbedCard">
          {view === 'table' ? (
            <AllAssetsTable
              entities={allAssetsData}
              error={error}
              loading={loading}
              checkFilterItem={checkClickItem}
              starredFilterItem={starredFilterItem || []}
              subscribeData={subscribeData || []}
              setClickSubscribeIcon={setClickSubscribeIcon}
              isClickSubscribeIcon={isClickSubscribeIcon}
              isAdmin={isAdmin}
            />
          ) : (
            <>
              {allAssetsData && (
                <AllAssetsCardGrid
                  entities={allAssetsData}
                  anchorElement={null}
                  loading={loading}
                  setAnchorElement={() => {}}
                  isAdmin={isAdmin}
                  checkFilterItem={checkClickItem}
                  starredFilterItem={starredFilterItem || []}
                  subscribeData={subscribeData || []}
                  setClickSubscribeIcon={setClickSubscribeIcon}
                  isClickSubscribeIcon={isClickSubscribeIcon}
                />
              )}
            </>
          )}
        </CatalogFilterLayout.Content>
      </CatalogFilterLayout>
    </div>
  );
};
