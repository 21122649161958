import React from 'react';
import { RadarPage } from './components/RadarPage';
import { useSidebarPinState } from '@backstage/core-components';

const TechRadar = () => {
  const { isPinned } = useSidebarPinState();

  const windowVerticleSpace = window.innerHeight - 250;
  const windowHorizontalSpace = isPinned
    ? window.innerWidth - 270
    : window.innerWidth - 110;
  return (
    <RadarPage width={windowHorizontalSpace} height={windowVerticleSpace} />
  );
};

export default TechRadar;
