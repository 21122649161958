import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ChatBotContext } from '../../providers/ChatBotProvider';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    width: '100%',
    textAlign: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    margin: '0 auto',
  },
  th: {
    width: '20%',
    textAlign: 'center',
    color: '#003F2D',
    backgroundColor: '#D7E1E2',
    paddingTop: '16px',
    paddingBottom: '24px',
  },
  tr: {
    borderTop: '2px solid #003F2D',
  },
  td: {
    textAlign: 'center',
    padding: '10px',
    color: '#003F2D',
  },
  projectTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  yearTitle: {
    textAlign: 'center',
  },
  quarterTitle: {
    fontWeight: 'bold',
  },
}));

export default function CustomizedTimeline(props: { data: any }) {
  const classes = useStyles();
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  const { setEnabled, enable } = useContext(ChatBotContext);
  const groupedData = props.data.reduce((acc: any, item: any) => {
    if (!acc[item.Project]) {
      acc[item.Project] = [];
    }
    acc[item.Project].push(item);
    return acc;
  }, {});

  const mappedData = Object.keys(groupedData).map((project) => {
    const projectData = groupedData[project];
    const quarterData = quarters.map((quarter) => {
      return projectData.filter((item: any) => item.TargetQuarter === quarter);
    });
    return { project, quarterData };
  });

  useEffect(() => {
    setEnabled(false);
  },[enable])

  return (
    <>
      <div className={classes.container}>
        <h3 className={classes.yearTitle}>2025</h3>
        <table className={classes.table}>
          <thead>
            <tr>
              <th style={{ width: '20%' }}></th>
              {quarters.map((q: string) => (
                <th key={q} className={classes.th}>
                  <Typography variant="body1" color="textSecondary" className={classes.quarterTitle}>
                    {q}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mappedData.map((quarterData: any, index: number) => (
              <tr key={index} className={classes.tr}>
                <td className={classes.projectTitle}>{quarterData.project}</td>
                {quarterData.quarterData.map((item: any, idx: number) => (
                  <td key={idx} className={classes.td}>
                    <ul>
                      {item.map((i: any, idx: number) => (
                        <li key={idx}>
                          <Typography variant="body2" color="textSecondary">
                            {i.Feature}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}