import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { IconComponent } from '@backstage/core-plugin-api';
import {
  SearchModal,
  SearchModalProps,
  SearchModalProvider,
  useSearchModal,
} from '@backstage/plugin-search';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

/**
 * Props for {@link SidebarSearchModal}.
 *
 * @public
 */
export type SidebarSearchModalProps = Pick<
  SearchModalProps,
  'children' | 'resultItemComponents'
> & {
  icon?: IconComponent;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchWrapper: {
      borderLeft: `0px !important`,
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    searchBar: {
      padding: `${theme.spacing(1)}px !important`,
      margin: `${theme.spacing(1)}px !important`,
      borderRadius: `${theme.spacing(1)}px`,
      backgroundColor: theme.palette.bgVariants.variant3,
    },
  }),
);

const SidebarSearchModalContent = (props: SidebarSearchModalProps) => {
  const { state, toggleModal } = useSearchModal();
  const classes = useStyles();

  return (
    <>
      <div
        className={classNames('emerald-nav-item')}
        role="menuitem"
        aria-label="menu item"
        onClick={toggleModal}
      >
        <div
          className={classNames('emerald-nav-wrapper', classes.searchWrapper)}
        >
          <div className={classNames('emerald-nav-link', classes.searchBar)}>
            <span className="emerald-nav-icon-pre">
              <i className="emerald-icon material-icons" aria-hidden="false">
                search
              </i>
            </span>
            <span className="emerald-nav-label" title="Home">
              Search
            </span>
          </div>
        </div>
      </div>

      <SearchModal
        {...state}
        toggleModal={toggleModal}
        resultItemComponents={props.resultItemComponents}
        children={props.children}
      />
    </>
  );
};

export const SidebarSearchModal = (props: SidebarSearchModalProps) => {
  return (
    <SearchModalProvider>
      <SidebarSearchModalContent {...props} />
    </SearchModalProvider>
  );
};
