import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';
import {
  IActivityLog,
  IAddToolRequest,
  IAddToolResponse,
  IApmDetails,
  IConfigData,
  ICreateAdminRequest,
  IAdmin,
  IGetApmsResponse,
  IGetProjectTools,
  IConfigOptionsResponse,
} from '../components/tools-provisioning/interfaces';
import { IPTRun } from '../components/tools-provisioning/interfaces';

export interface ToolsProvisioningApi {
  getApms: () => Promise<IGetApmsResponse>;
  getApmInfoById: (apmNumber: string) => Promise<IApmDetails>;
  // Tools Management
  addToolToProject: (tools: IAddToolRequest) => Promise<IAddToolResponse>;
  getProjectTools: (apmNumber: string) => Promise<IGetProjectTools>;
  getActivityLog: (apmNumber: string) => Promise<IActivityLog[]>;

  // Admin Apis
  createAdmin: (data: ICreateAdminRequest) => Promise<IAdmin>;
  deleteAdmin: (id: string) => Promise<any>;
  getAllAdmins: (apmNumber: string) => Promise<IAdmin[]>;
  getAmpKeysFromEmail: (email: string) => Promise<string[]>;

  // Performace Test Related Apis
  getConfigOptions: () => Promise<IConfigOptionsResponse>;
  savePTConfig: (config: any, validate?: boolean) => Promise<any>;
  updatePTConfig: (config: any, validate?: boolean) => Promise<any>;
  deletePTConfig: (configId: string) => Promise<any>;
  runPTConfig: (configId: string) => Promise<any>;
  validatePTConfig: (configId: string) => Promise<any>;
  getPTConfigs: (apmNumber: string) => Promise<IConfigData[] | undefined>;
  // Performace Test - Runs
  getPTRuns: (apmNumber: string) => Promise<IPTRun[]>;
  stopPTRun: (immutableId: string) => Promise<any>;
  downloadRunResults: (immutableId: string) => Promise<any>;
  getStatus: (immutableId: string) => Promise<any>;

  // Planner QE Bot
  getProjects: (apmNumber: string) => Promise<any>;
  getProvisionedProjectDetails: (
    apmNumber: string,
    service: string,
  ) => Promise<any>;
  getProvisionedTools: (apmNumber: string, tool: string) => Promise<any>;
}

export const toolsProvisioningApiRef = createApiRef<ToolsProvisioningApi>({
  id: 'cbre.devx.api.toolsprovisioning',
});

export class ToolsProvisioningApiImpl implements ToolsProvisioningApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getApms(): Promise<IGetApmsResponse> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/registration/apminfo`)
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting repo info ${err.message}`,
        );
      });
  }

  async getApmInfoById(apmNumber: string): Promise<IApmDetails> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/registration/apminfo/${apmNumber}`)
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting repo info ${err.message}`,
        );
      });
  }

  /* Tools Management Services */

  /* Add Tool to project */
  async addToolToProject(tool: IAddToolRequest): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'post',
        url: `service/provision/tool`,
        data: { ...tool },
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while adding tool ${err.message}`);
      });
  }

  /* get tools of the project */
  async getProjectTools(apmNumber: string): Promise<IGetProjectTools> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/provision/tool/status/${apmNumber}`)
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting tools status ${err.message}`,
        );
      });
  }

  /* get tool Activity log */
  async getActivityLog(apmNumber: string): Promise<IActivityLog[]> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/provision/tool/activitylog/${apmNumber}`)
      .then(res => res?.data)
      .catch(err => {
        throw new Error(
          `Error occurred while getting tools status ${err.message}`,
        );
      });
  }

  /* Create Admin for Application/Project */
  async createAdmin(adminObj: ICreateAdminRequest): Promise<IAdmin> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'post',
        url: `service/registration/admin`,
        data: { ...adminObj },
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while creating admin ${err.message}`);
      });
  }

  /* Delete Admin for Application/Project */
  async deleteAdmin(id: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'delete',
        url: `service/registration/admin/${id}`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while deleting admin ${err.message}`);
      });
  }

  /* Get all admins of Application/Project */
  async getAllAdmins(apmNumber: string): Promise<IAdmin[]> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/registration/admin/apminfo/${apmNumber}`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while getting admins ${err.message}`);
      });
  }

  /* Get amp keys from email */
  async getAmpKeysFromEmail(email: string): Promise<string[]> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/registration/admin/${email}`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error Apm keys for the email ${err.message}`);
      });
  }

  /* Get config options of Application/Project */
  async getConfigOptions(): Promise<IConfigOptionsResponse> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/provision/performancetest/options`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while getting config options ${err.message}`);
      });
  }

  /* Save Config for Performace Test */
  async savePTConfig(config: any, validate?: boolean): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'post',
        url: `service/provision/performancetest/config${
          validate ? '?validate=true' : ''
        }`,
        data: { ...config },
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while saving config ${err.message}`);
      });
  }

  /* Update Config for Performace Test */
  async updatePTConfig(config: any, validate?: boolean): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'put',
        url: `service/provision/performancetest/config${
          validate ? '?validate=true' : ''
        }`,
        data: { ...config },
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while updating config ${err.message}`);
      });
  }

  /* delete Config for Performace Test */
  async deletePTConfig(configId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'delete',
        url: `service/provision/performancetest/config/${configId}`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while deleteing config ${err.message}`);
      });
  }

  /* run Config for Performace Test */
  async runPTConfig(configId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'post',
        url: `service/provision/performancetest/run/${configId}`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while running config ${err.message}`);
      });
  }

  // Validate existing config
  async validatePTConfig(configId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'post',
        url: `service/provision/performancetest/config/${configId}/validate`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while validating config ${err.message}`);
      });
  }

  /* Get all configs for Performance Test */
  async getPTConfigs(apmNumber: string): Promise<IConfigData[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/provision/performancetest/config/apm/${apmNumber}`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while getting configs ${err.message}`);
      });
  }

  /* Get all runs for Performance Test */
  async getPTRuns(apmNumber: string): Promise<IPTRun[]> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/provision/performancetest/runs/${apmNumber}`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while getting runs ${err.message}`);
      });
  }

  /* stop run for Performace Test */
  async stopPTRun(immutableId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'post',
        url: `service/provision/performancetest/run/stop/${immutableId}`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while stopping run ${err.message}`);
      });
  }
  /* download run results for Performace Test */
  async downloadRunResults(immutableId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/provision/performancetest/run/results/${immutableId}`,
      })
      .then(res => res?.data)
      .catch(err => {
        throw new Error(`Error while downloading run results ${err.message}`);
      });
  }

  async getStatus(immutableId: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/provision/performancetest/run/results/${immutableId}`,
      })
      .then(res => res)
      .catch(err => {
        throw new Error(`Error while getting status ${err.message}`);
      });
  }

  async getProjects(apmNumber: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/provision/apm/${apmNumber}/jira`,
      })
      .then(res => res)
      .catch(err => {
        throw new Error(`Error while getting status ${err.message}`);
      });
  }

  async getProvisionedProjectDetails(
    apmNumber: string,
    service: string,
  ): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/productmapping/${apmNumber}/${
          service === 'jira' ? 'JIRA' : 'ADO'
        }/Project`,
      })
      .then(res => res)
      .catch(err => {
        throw new Error(`Error while getting status ${err.message}`);
      });
  }

  async getProvisionedTools(apmNumber: string, tool: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .request({
        method: 'get',
        url: `service/provision/tool/status/${apmNumber}/${
          tool === 'jira' ? 'enable-jiraPlannerBot' : 'enable-ADOPlannerBot'
        }/any`,
      })
      .then(res => res)
      .catch(err => {
        throw new Error(`Error while getting status ${err.message}`);
      });
  }
}
