import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoPath: {
      fill: theme.palette.text.primary,
      fillRule: 'nonzero',
    },
    signInVariant: {
      fill: '#fff',
      fillRule: 'nonzero',
    },
  }),
);

export type ILogoFullProps = {
  isSignInPage?: boolean;
  size?: 'medium' | 'large';
};
const LogoFull = ({ isSignInPage = false, size = 'large' }: ILogoFullProps) => {
  const classes = useStyles();
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1103 275"
      version="1.1"
      aria-label="cbre-logo"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2',
        width: `${size === 'medium' ? '96px' : '150px'}`,
      }}
    >
      <path
        d="M862.862,0.041l239.91,-0l-0.125,59.7l-173.125,-0l0.175,44.09l156.066,0l-0,60.013l-156.066,-0l-0,47.722l173.14,-0l-0.043,62.559l-239.932,0l0,-274.084Zm-139.556,0.356l-145.084,-0l-0,273.647l66.619,-0l-0,-110.728c-0,-0 73.362,-0.069 73.372,-0.069c21.015,0.316 37.356,17.525 37.356,38.753l-0,71.803l65.212,-0.019l-0.125,-88.318c0,-40.928 -39.525,-53.013 -39.525,-53.013c0,0 40.41,-12.078 40.41,-60.45c-0,-59.187 -50.854,-71.606 -98.235,-71.606Zm-467.84,207.525c-0.96,0.019 -91.072,1.759 -122.182,-1.272c-50.096,-4.909 -67.853,-42.022 -67.853,-72.319c0,-37.775 25.747,-65.569 65.638,-70.787c18.984,-2.491 122.74,-1.31 123.85,-1.297l2.172,0.022l0.218,-62.228l-2.193,0.006l-117.444,0.215c-13.078,0.804 -34.756,2.11 -56.96,11.188c-21.325,9.778 -39.875,24.781 -53.737,43.45c-17.641,23.766 -26.975,51.984 -26.975,81.566c0,9.312 0.738,18.543 2.238,27.406c8.45,41.494 35.649,75.831 74.646,94.294c13.169,5.8 33.475,12.743 74.432,15.04c0.087,0 22.072,0.463 22.072,0.463c-0,0.019 81.874,0.087 81.874,0.087l2.16,0l0.231,-65.865l-2.187,0.031Zm245.922,-75.325c34.849,-11.584 41.168,-40.831 41.084,-64.678c-0.063,-36.413 -25.766,-67.897 -101.825,-67.897l-141.975,-0.022l-0,273.559l141.603,0c76.519,0 104.675,-39.287 104.675,-78.637c0,-47.65 -43.562,-62.325 -43.562,-62.325Zm-44.344,77.203l-90.997,0l-0,-45.8l93.219,-0l0.215,0.031c11.6,1.338 20.378,10.972 20.378,22.432c0,12.481 -10.397,23.162 -22.815,23.337Zm-90.997,-150.284l95.594,-0l0.343,0.065c10.788,1.91 18.638,11.091 18.638,21.847c-0,11.016 -8.409,21.391 -19.531,22.9l-94.979,0.156l-0.065,-44.968Zm393.622,25.775c-1.191,9 -10.185,18.762 -21.416,18.762l-93.64,0.028l-0,-43.809l93.674,-0c11.197,-0 19.957,8.234 21.382,19.047l-0,5.972Z"
        className={isSignInPage ? classes.signInVariant : classes.logoPath}
      />
    </svg>
  );
};

export default LogoFull;
