import React, { useEffect, useContext } from 'react';
import { Entity } from '@backstage/catalog-model';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import {
  getEntitySourceLocation,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Button,
  createStyles,
  Theme,
} from '@material-ui/core';

import { AboutContent } from './AboutContent';

import {
  // HeaderIconLinkRow,
  IconLinkVerticalProps,
  InfoCardVariants,
} from '@backstage/core-components';
import AzureIcon from '../../../../Root/icons/azureIcon';
import GitHubIcon from '@material-ui/icons/GitHub';
import { useApi } from '@backstage/core-plugin-api';
import { Link, useMatch } from 'react-router-dom';

const WSO2 = 'wso2';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemCard: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 10px)', // for pages without content header
      marginBottom: '10px',
    },
    fullHeightCard: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    gridItemCardContent: {
      flex: 1,
    },
    fullHeightCardContent: {
      flex: 1,
    },
    editApiBtn: {
      marginRight: '10px',
      marginTop: '10px',
    },
    apiCardHeader: {
      gap: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5),
      flexWrap: 'wrap',
    },
    headerActions: {
      flex: '0 1 auto',
    },
  }),
);

type AboutCardProps = {
  variant?: InfoCardVariants;
  userId: String;
  isAdmin?: Boolean | null;
};

export function ApiAboutCard({ userId, isAdmin }: AboutCardProps) {
  const classes = useStyles();
  const { entity } = useAsyncEntity();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const entitySourceLocation = getEntitySourceLocation(
    entity,
    scmIntegrationsApi,
  );
  const entityOwner = entity?.spec?.owner?.replace('user:', '');

  const viewInSource: IconLinkVerticalProps = {
    label: 'View Source',
    disabled: !entitySourceLocation,
    icon:
      entitySourceLocation?.integrationType?.toLowerCase() === 'github' ? (
        <GitHubIcon />
      ) : entitySourceLocation?.integrationType?.toLowerCase() === 'azure' ? (
        <AzureIcon />
      ) : null,
    href: entitySourceLocation?.locationTargetUrl,
  };

  const name = entity.metadata.name;
  const kind = entity.kind;
  const namespace = entity.metadata.namespace;

  const mountedOnAPIPath = useMatch('/API/:namespace/:kind/:name');
  const mountedOnCatalogPath = useMatch('/catalog/:namespace/:kind/:name');

  const getEditUrl = () => {
    if (
      mountedOnAPIPath !== undefined &&
      mountedOnAPIPath?.pathname !== undefined
    ) {
      return `/API/${namespace}/${kind}/${name}/api-edit`;
    } else if (
      mountedOnCatalogPath !== undefined &&
      mountedOnCatalogPath?.pathname !== undefined
    ) {
      return `/catalog/${namespace}/${kind}/${name}/api-edit`;
    }
    return `/catalog/${namespace}/${kind}/${name}/api-edit`;
  };
  const link: string = entity?.metadata?.documentationLink as string || '';
  console.log(entity.metadata.assetstore)

  return (
    <Card className={classes.gridItemCard}>
      <CardHeader
        title="About"
        classes={{ action: classes.headerActions, root: classes.apiCardHeader }}
        action={
          <div>
            {
              entity?.metadata?.assetstore === WSO2 && <>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  target="_blank"
                  href={`${window.location.origin}/help/apiSubscribe/`}
                  className={classes.editApiBtn}
                >
                  How to Subscribe
                </Button>
                {/* <Button
                  variant="contained"
                  size="small"
                  href={`https://cbre-jira.atlassian.net/wiki/spaces/AM/pages/38044159/How+to+Publish+an+API`}
                  target="_blank"
                  color="primary"
                  className={classes.editApiBtn}
                >
                  API Instructions
                </Button> */}
              </>
            }
            {entity &&
              entity?.metadata &&
              entity?.metadata?.documentationLink ? (
                <Link
                  to={
                  entity?.metadata?.assetstore === WSO2 ? 
                    'https://apimanager.cbre.com/devportal'
                    : link
                  }
                  target="_blank"
                >
                  <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.editApiBtn}
                  >
                  Get Started
                  </Button>
                </Link>
            ) : null}
            {userId.replace('user:', '').toLowerCase() ===
              entityOwner.replace('user:', '').toLowerCase() || isAdmin ? (
              <Link to={getEditUrl()}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.editApiBtn}
                >
                  Edit API
                </Button>
              </Link>
            ) : null}
          </div>
        }
      // subheader={
      //   <HeaderIconLinkRow links={[viewInSource]} />
      // }
      />
      <Divider />
      <CardContent className={classes.gridItemCardContent}>
        <AboutContent entity={entity} />
      </CardContent>
    </Card>
  );
}
