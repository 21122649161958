import {
  Link,
  sidebarConfig,
  useSidebarOpenState,
  useSidebarPinState,
} from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import LogoFull from '../logo/LogoFull';
import React from 'react';
import LogoIcon from '../logo/LogoIcon';
import useMutationObserver from './hooks/useMutationObserver';

const useSidebarLogoStyles = makeStyles({
  root: {
    height: '66px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 9,
    display: 'block',
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  // const { isOpen } = useSidebarOpenState();
  const { isPinned } = useSidebarPinState();
  const isOpen = useMutationObserver(isPinned);

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull size="medium" /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export default SidebarLogo;
