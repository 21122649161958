import {
  Content,
  Header,
  Page,
  useSidebarPinState,
} from '@backstage/core-components';
import {
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Theme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { RadarComponent, type TechRadarComponentProps } from './RadarComponent';
import Legend from './Legend';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  overflowXAuto: {
    overflowX: 'auto',
  },

  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  legend: {
    position: 'relative',
    left: `calc(50% - 192px)`,
    fontSize: `${theme.spacing(1.625)}px`,
  },
  searchBar: {
    height: '40px',
    padding: `${theme.spacing(0.5)}px`,
  },
  searchForm: {
    [theme.breakpoints.down('lg')]: {
      width: `${theme.spacing(18)}px`,
    },
  },
  radarFilters: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    alignItems: 'center',
    justifyContent: 'end',
  },
  categoryFilter: {
    maxWidth: `${theme.spacing(22)}px`,
    [theme.breakpoints.down('md')]: {
      width: `${theme.spacing(18)}px`,
    },
  },
  statusDropdown: {
    padding: `${theme.spacing(1.375)}px ${theme.spacing(1.875)}px`,
    background: theme.palette.background.paper,
  },
  flex: {
    display: 'flex',
  },
  gap1: {
    gap: theme.spacing(1),
  },
}));

export interface TechRadarPageProps extends TechRadarComponentProps {
  title?: string;
  subtitle?: string;
  pageTitle?: string;
}

export function RadarPage(props: TechRadarPageProps) {
  const {
    title = 'Tech Radar',
    subtitle = 'Pick the recommended technologies for your projects',
    pageTitle = 'Company Radar',
    ...componentProps
  } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');
  const [filterOption, setFilterOption] = useState({
    category: 'All categories',
  });
  const [categories, setCategories] = useState<any>([]);

  const [legends, setLegends] = useState([
    {
      name: 'Approved',
      bg: '#50AF78',
      selected: true,
    },
    {
      name: 'Restricted',
      bg: '#FF9742',
      selected: false,
    },
    {
      name: 'Prohibited',
      bg: '#7774d8',
      selected: false,
    },
    {
      name: 'Trial',
      bg: '#FF543E',
      selected: false,
    },
  ]);

  return (
    <Page themeId="tool">
      <Header title={title} subtitle={subtitle} />
      <Content className={classes.overflowXAuto}>
        {/* <ContentHeader title={pageTitle}> */}
        <Grid container>
          <Grid item xs={12} className={classes.spaceBetween}>
            <div className={classes.legend}>
              <Legend legends={legends} setLegends={setLegends} />
            </div>
            <div className={classNames(classes.flex, classes.gap1)}>
              <FormControl className={classes.categoryFilter}>
                <InputLabel shrink variant="outlined">
                  Categories
                </InputLabel>
                <Select
                  value={filterOption.category}
                  label="Categories"
                  multiline
                  variant="outlined"
                  className={classes.statusDropdown}
                  onChange={(val: any) => {
                    setFilterOption({
                      ...filterOption,
                      category: val.target.value,
                    });
                  }}
                >
                  {categories &&
                    categories.map((t: string) => (
                      <MenuItem key={`type-option-${t}`} value={t}>
                        {t}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Paper
                component="form"
                variant="outlined"
                className={classes.searchForm}
              >
                <InputBase
                  className={classes.searchBar}
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'Search' }}
                  onChange={e => setSearchText(e.target.value)}
                  onKeyDown={ev => {
                    if (ev.key === 'Enter') {
                      ev.preventDefault();
                    }
                  }}
                  endAdornment={<SearchIcon />}
                />
              </Paper>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} direction="row">
          <Grid item xs={12} sm={12} md={12}>
            <RadarComponent
              searchText={searchText}
              {...componentProps}
              filterOption={filterOption}
              legends={legends}
              onSetCategories={setCategories}
            />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
}
