/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Button,
  Typography,
  DialogActions,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../styles';
import AdditionalDetailsCard from '../cards/AdditionalDetailsCard';
import { useNavigate, useParams } from 'react-router';
import { AuthContext } from '../../../../providers/AuthProvider';
import ConfirmToolCard from '../cards/ConfirmToolCard';
import { useAddTool } from '../../hooks/useAddTool';
import { TPContext } from '../../providers/TPProvider';
import { IAvailableTool } from '../../interfaces';
import ConfirmMsg from '../cards/ConfirmMsg';

export interface AddToolProps {
  open: boolean;
  onClose: Function;
  onAddToolClicked: Function;
  onAddToolSuccess: Function;
  toolToAdd: IAvailableTool | undefined;
  actionType: 'provision' | 'deprovision';
}

const AddTool = ({
  open,
  onClose,
  onAddToolClicked,
  onAddToolSuccess,
  toolToAdd,
  actionType,
}: AddToolProps) => {
  const classes = useStyles();

  const { projectId } = useParams();
  const { profEmail } = useContext(AuthContext);
  const { apmInfo } = useContext(TPContext);

  const [requestToolObject, setRequestToolObject] = useState({
    'apm-number': projectId || '',
    tool: '',
    'instance-name': 'ADO project name',
    'provision-key': projectId || '',
    'requestor-principal': profEmail,
    'env-prod': !/internal\.|dev\.|uat\./.test(window.location.href),
    'additional-data': {},
    ...(actionType === 'deprovision' && { 'is-unprovision': true }),
  });

  const updateRequestToolObj = (objToInsert: any) => {
    setRequestToolObject((prevState: any) => {
      return { ...prevState, ...objToInsert };
    });
  };

  useEffect(() => {
    updateRequestToolObj({
      tool: toolToAdd?.tool,
      'instance-name': `${toolToAdd?.tool} ${apmInfo?.Name}`,
    });
  }, [toolToAdd]);

  const handleAdditionalDetails = (additionalData: any) => {
    updateRequestToolObj({ 'additional-data': additionalData });
  };

  const [addtool] = useAddTool();

  const navigate = useNavigate();
  const handleAddTool = () => {
    switch (toolToAdd?.tool) {
      case 'enable-prbot': {
        onAddToolClicked();
        addtool(requestToolObject).then(reason => {
          onAddToolSuccess(reason);
        });
        break;
      }
      case 'enable-performanceTest': {
        addtool(requestToolObject).then(reason => {
          if (actionType === 'provision') navigate('./performance-test/runs');
          else onAddToolSuccess(reason);
        });

        break;
      }
      default: {
        // Handle the default case here
        break;
      }
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6">
          {actionType === 'provision'
            ? 'Are you sure you want to add this service?'
            : 'Are you sure you want to disable this service?'}
        </Typography>
        <IconButton className={classes.closeButton} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* <ConfirmToolCard data={requestToolObject} /> */}
        {/* <Divider /> */}

        {/* If inputs are present, create a from renderer in Additiona Details card component, It might change later */}
        {/* {toolToAdd?.inputs ? (
          <AdditionalDetailsCard
            tool={toolToAdd?.inputs}
            onAdditionalDataChange={handleAdditionalDetails}
          />
        ) : null} */}

        <Typography variant="h6">{toolToAdd?.metadata.name}</Typography>
        {actionType === 'provision' ? (
          <ConfirmMsg tool={toolToAdd!} />
        ) : (
          'This feature will be disabled from the project.'
        )}
      </DialogContent>

      <DialogActions>
        <Grid>
          <Button className={classes.actionButton} onClick={() => onClose()}>
            Cancel
          </Button>

          <Button className={classes.actionButton} onClick={handleAddTool}>
            {actionType === 'provision' ? 'Accept & Add' : 'Disable'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddTool;
